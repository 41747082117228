.services-section ul li {
	float: left;
	background-color: black;
	height: 150px;
}

.services-section span.service-inner {
	padding: 20px 0 20px 0;
}

.services-section {
	height: 730px;
	margin-top: 50px;
	margin-bottom: 50px;
}

.services-section img.services-icon {
	margin-top: 50px;
}

.service-links ul {
	margin: 100px 0 0 0 !important;
}

.more-info {
	margin-bottom: 50px;
}

.service-gallery {
	margin-bottom: 50rem;
}

.service-highlights ul {
	margin: 50px 0 0 0;
}

.services-info {
	width: 60% !important;
}

.service-highlights {
	width: 40% !important;
}

.hdr-message {
	margin: 180px auto auto;
}

.services-opener {
	margin: 0 auto;
}

.projectTitle {
	opacity: 1 !important;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
  filter: alpha(opacity=100) !important;
	display: inherit !important;
	margin: 30% auto auto !important;
	top: 0 !important;
}

.hideme {
	opacity: 1 !important;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" !important;
  filter: alpha(opacity=100) !important;
}

.service-gallery ul li {
	float: left;
	height: 250px;
	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(
	sizingMethod='scale');
	-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(
	sizingMethod='scale')";
}

.service-gallery ul li.previousProjects {
	padding-top: 8rem;
}

.no-background-size {
	min-height: 100%;
	min-width: 100%;
}

.mask {
	border-top: none !important;
}

.aboutusQuote {
	padding: 35px 0 0 0 !important;
}

.checkatradeBtn {
	padding-right: 20px !important;
}

.contactDetails-inner {
	padding: 60px;
}

div.md-overlayImg {
    height: 50%;
    width: 50%;
		padding-right: 100px;
}

.md-overlay div {
	padding-left: 100px;
}

li.page_item a::before {
	margin-left: -30px;
}

li.current_page_item a::before {
	margin-left: -30px;
}
